// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-gallery-index-js": () => import("/zeit/796d803b/src/templates/Gallery/index.js" /* webpackChunkName: "component---src-templates-gallery-index-js" */),
  "component---src-pages-404-js": () => import("/zeit/796d803b/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-confirmar-encomenda-js": () => import("/zeit/796d803b/src/pages/confirmar-encomenda.js" /* webpackChunkName: "component---src-pages-confirmar-encomenda-js" */),
  "component---src-pages-contactos-js": () => import("/zeit/796d803b/src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-galeria-js": () => import("/zeit/796d803b/src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("/zeit/796d803b/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("/zeit/796d803b/src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-bebes-js": () => import("/zeit/796d803b/src/pages/portfolio/bebes.js" /* webpackChunkName: "component---src-pages-portfolio-bebes-js" */),
  "component---src-pages-portfolio-casamento-js": () => import("/zeit/796d803b/src/pages/portfolio/casamento.js" /* webpackChunkName: "component---src-pages-portfolio-casamento-js" */),
  "component---src-pages-portfolio-familia-js": () => import("/zeit/796d803b/src/pages/portfolio/familia.js" /* webpackChunkName: "component---src-pages-portfolio-familia-js" */),
  "component---src-pages-portfolio-gravida-js": () => import("/zeit/796d803b/src/pages/portfolio/gravida.js" /* webpackChunkName: "component---src-pages-portfolio-gravida-js" */),
  "component---src-pages-portfolio-recem-nascido-js": () => import("/zeit/796d803b/src/pages/portfolio/recem-nascido.js" /* webpackChunkName: "component---src-pages-portfolio-recem-nascido-js" */),
  "component---src-pages-portfolio-smash-the-cake-js": () => import("/zeit/796d803b/src/pages/portfolio/smash-the-cake.js" /* webpackChunkName: "component---src-pages-portfolio-smash-the-cake-js" */),
  "component---src-pages-showcase-bebes-1-js": () => import("/zeit/796d803b/src/pages/showcase/bebes/1.js" /* webpackChunkName: "component---src-pages-showcase-bebes-1-js" */),
  "component---src-pages-showcase-bebes-2-js": () => import("/zeit/796d803b/src/pages/showcase/bebes/2.js" /* webpackChunkName: "component---src-pages-showcase-bebes-2-js" */),
  "component---src-pages-showcase-bebes-3-js": () => import("/zeit/796d803b/src/pages/showcase/bebes/3.js" /* webpackChunkName: "component---src-pages-showcase-bebes-3-js" */),
  "component---src-pages-showcase-casamentos-1-js": () => import("/zeit/796d803b/src/pages/showcase/casamentos/1.js" /* webpackChunkName: "component---src-pages-showcase-casamentos-1-js" */),
  "component---src-pages-showcase-casamentos-2-js": () => import("/zeit/796d803b/src/pages/showcase/casamentos/2.js" /* webpackChunkName: "component---src-pages-showcase-casamentos-2-js" */),
  "component---src-pages-showcase-casamentos-3-js": () => import("/zeit/796d803b/src/pages/showcase/casamentos/3.js" /* webpackChunkName: "component---src-pages-showcase-casamentos-3-js" */),
  "component---src-pages-showcase-casamentos-4-js": () => import("/zeit/796d803b/src/pages/showcase/casamentos/4.js" /* webpackChunkName: "component---src-pages-showcase-casamentos-4-js" */),
  "component---src-pages-showcase-familia-1-js": () => import("/zeit/796d803b/src/pages/showcase/familia/1.js" /* webpackChunkName: "component---src-pages-showcase-familia-1-js" */),
  "component---src-pages-showcase-gravida-1-js": () => import("/zeit/796d803b/src/pages/showcase/gravida/1.js" /* webpackChunkName: "component---src-pages-showcase-gravida-1-js" */),
  "component---src-pages-showcase-gravida-2-js": () => import("/zeit/796d803b/src/pages/showcase/gravida/2.js" /* webpackChunkName: "component---src-pages-showcase-gravida-2-js" */),
  "component---src-pages-showcase-gravida-3-js": () => import("/zeit/796d803b/src/pages/showcase/gravida/3.js" /* webpackChunkName: "component---src-pages-showcase-gravida-3-js" */),
  "component---src-pages-showcase-gravida-4-js": () => import("/zeit/796d803b/src/pages/showcase/gravida/4.js" /* webpackChunkName: "component---src-pages-showcase-gravida-4-js" */),
  "component---src-pages-showcase-gravida-5-js": () => import("/zeit/796d803b/src/pages/showcase/gravida/5.js" /* webpackChunkName: "component---src-pages-showcase-gravida-5-js" */),
  "component---src-pages-showcase-gravida-6-js": () => import("/zeit/796d803b/src/pages/showcase/gravida/6.js" /* webpackChunkName: "component---src-pages-showcase-gravida-6-js" */),
  "component---src-pages-showcase-gravida-7-js": () => import("/zeit/796d803b/src/pages/showcase/gravida/7.js" /* webpackChunkName: "component---src-pages-showcase-gravida-7-js" */),
  "component---src-pages-showcase-gravida-8-js": () => import("/zeit/796d803b/src/pages/showcase/gravida/8.js" /* webpackChunkName: "component---src-pages-showcase-gravida-8-js" */),
  "component---src-pages-showcase-gravida-9-js": () => import("/zeit/796d803b/src/pages/showcase/gravida/9.js" /* webpackChunkName: "component---src-pages-showcase-gravida-9-js" */),
  "component---src-pages-showcase-recem-nascido-1-js": () => import("/zeit/796d803b/src/pages/showcase/recem-nascido/1.js" /* webpackChunkName: "component---src-pages-showcase-recem-nascido-1-js" */),
  "component---src-pages-showcase-recem-nascido-10-js": () => import("/zeit/796d803b/src/pages/showcase/recem-nascido/10.js" /* webpackChunkName: "component---src-pages-showcase-recem-nascido-10-js" */),
  "component---src-pages-showcase-recem-nascido-11-js": () => import("/zeit/796d803b/src/pages/showcase/recem-nascido/11.js" /* webpackChunkName: "component---src-pages-showcase-recem-nascido-11-js" */),
  "component---src-pages-showcase-recem-nascido-12-js": () => import("/zeit/796d803b/src/pages/showcase/recem-nascido/12.js" /* webpackChunkName: "component---src-pages-showcase-recem-nascido-12-js" */),
  "component---src-pages-showcase-recem-nascido-13-js": () => import("/zeit/796d803b/src/pages/showcase/recem-nascido/13.js" /* webpackChunkName: "component---src-pages-showcase-recem-nascido-13-js" */),
  "component---src-pages-showcase-recem-nascido-14-js": () => import("/zeit/796d803b/src/pages/showcase/recem-nascido/14.js" /* webpackChunkName: "component---src-pages-showcase-recem-nascido-14-js" */),
  "component---src-pages-showcase-recem-nascido-2-js": () => import("/zeit/796d803b/src/pages/showcase/recem-nascido/2.js" /* webpackChunkName: "component---src-pages-showcase-recem-nascido-2-js" */),
  "component---src-pages-showcase-recem-nascido-3-js": () => import("/zeit/796d803b/src/pages/showcase/recem-nascido/3.js" /* webpackChunkName: "component---src-pages-showcase-recem-nascido-3-js" */),
  "component---src-pages-showcase-recem-nascido-4-js": () => import("/zeit/796d803b/src/pages/showcase/recem-nascido/4.js" /* webpackChunkName: "component---src-pages-showcase-recem-nascido-4-js" */),
  "component---src-pages-showcase-recem-nascido-5-js": () => import("/zeit/796d803b/src/pages/showcase/recem-nascido/5.js" /* webpackChunkName: "component---src-pages-showcase-recem-nascido-5-js" */),
  "component---src-pages-showcase-recem-nascido-6-js": () => import("/zeit/796d803b/src/pages/showcase/recem-nascido/6.js" /* webpackChunkName: "component---src-pages-showcase-recem-nascido-6-js" */),
  "component---src-pages-showcase-recem-nascido-7-js": () => import("/zeit/796d803b/src/pages/showcase/recem-nascido/7.js" /* webpackChunkName: "component---src-pages-showcase-recem-nascido-7-js" */),
  "component---src-pages-showcase-recem-nascido-8-js": () => import("/zeit/796d803b/src/pages/showcase/recem-nascido/8.js" /* webpackChunkName: "component---src-pages-showcase-recem-nascido-8-js" */),
  "component---src-pages-showcase-recem-nascido-9-js": () => import("/zeit/796d803b/src/pages/showcase/recem-nascido/9.js" /* webpackChunkName: "component---src-pages-showcase-recem-nascido-9-js" */),
  "component---src-pages-showcase-smash-the-cake-1-js": () => import("/zeit/796d803b/src/pages/showcase/smash-the-cake/1.js" /* webpackChunkName: "component---src-pages-showcase-smash-the-cake-1-js" */),
  "component---src-pages-showcase-smash-the-cake-2-js": () => import("/zeit/796d803b/src/pages/showcase/smash-the-cake/2.js" /* webpackChunkName: "component---src-pages-showcase-smash-the-cake-2-js" */),
  "component---src-pages-sobre-nos-js": () => import("/zeit/796d803b/src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/zeit/796d803b/.cache/data.json")

